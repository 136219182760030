import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import IndexLayout from 'layouts/IndexLayout';
import { Features, SEO } from 'components';
import { InnerWrap } from 'components/common/Containers/styled';
import { DarkScheme } from 'helpers/navColors.js';

const Filter = styled.div`
  padding: 0 0 40px;
`;

const FeaturesIndex = ({ data }) => {
  const { allFeatures, interviewFeatures, listFeatures, reviewFeatures } = data;
  const [visibleCategory, setVisibleCategory] = useState(allFeatures);

  const handleChangeFilter = (filter) => {
    setVisibleCategory(filter);
  };

  return (
    <IndexLayout title='Features' navColor={DarkScheme}>
      <SEO title='Japanese music interviews, reviews and lists' />
      <InnerWrap>
        <Filter>
          <button
            onClick={() => handleChangeFilter(allFeatures)}
            className={visibleCategory === allFeatures && 'active'}
          >
            View All
          </button>
          <button
            onClick={() => handleChangeFilter(interviewFeatures)}
            className={visibleCategory === interviewFeatures && 'active'}
          >
            Interviews
          </button>
          <button
            onClick={() => handleChangeFilter(listFeatures)}
            className={visibleCategory === listFeatures && 'active'}
          >
            Lists
          </button>
          <button
            onClick={() => handleChangeFilter(reviewFeatures)}
            className={visibleCategory === reviewFeatures && 'active'}
          >
            Reviews
          </button>
        </Filter>
        <Features {...visibleCategory} />
      </InnerWrap>
    </IndexLayout>
  );
};

export const query = graphql`
  query {
    allFeatures: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { name: { ne: "Selector" } } }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
    interviewFeatures: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: {
        subcategory: { name: { ne: "Selector" } }
        category: { name: { eq: "Interview" } }
      }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
    listFeatures: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: {
        subcategory: { name: { ne: "Selector" } }
        category: { name: { eq: "List" } }
      }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
    reviewFeatures: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: {
        subcategory: { name: { ne: "Selector" } }
        category: { name: { eq: "Review" } }
      }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
  }
`;

export default FeaturesIndex;
